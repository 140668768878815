<template>
  <div class="vh-100 d-flex align-items-center">
    <section class="background-koesio d-xl-flex align-items-center">
      <p class="kam-logo d-xl-flex d-none">KAM</p>
    </section>
    <div class="row no-gutters ml-xl-3 col-12">
      <div class="col-7 d-xl-block d-none">
        <LottieAnimation animation-link="https://assets7.lottiefiles.com/packages/lf20_vLSIWiEpIC.json" :height="400" :width="400" />
      </div>
      <div class="col-xl-5 col-12 login-form">
        <div class="text-center mb-5 center"><img src="@/assets/img/k.jpg" /></div>
        <div class="card card-container center" style="margin: 50px">
          <Form @submit="handleLogin" :validation-schema="schema" id="formLogin">
            <div class="form-group mt-3 mb-2">
              <Field name="username" type="text" class="form-control" placeholder="Nom d'utilisateur"/>
              <ErrorMessage name="username" class="error-feedback" />
            </div>
            <div class="form-group mt-5 mb-3">
              <Field name="password" type="password" class="form-control" placeholder="Mot de passe"/>
              <ErrorMessage name="password" class="error-feedback" />
            </div>
            <div class="form-group">
              <button class="btn-round" :disabled="loading">
                <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm mar-15"
                ></span>
                <span>Se connecter</span>
              </button>
            </div>
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center footer fixed-bottom pb-2">
        Fink v3.3.3
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";


export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Le nom d'utilisateur est obligatoire"),
      password: yup.string().required("Le mot de passe est obligatoire"),
    });
    return {
      loading: false,
      message: "",
      schema,
      options: {
        minimizable: false,
        playerSize: "standard",
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        theme: {
          controlsView: "standard",
          active: "light",
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          }
        }
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getUser;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
   async handleLogin(user) {
     this.loading = true;
     this.$http.post('/api/login', {
       login: user.username,
       password: user.password,
     })
         .then(async (response) => {
           if (response.data.success.token) {
             await this.$store.commit('setToken', response.data.success.token)
             let date = new Date()
             date.setDate(date.getDate() + 1)
             await this.$store.commit('setDate', date.getTime())
             this.$http.defaults.headers.common['DOLAPIKEY'] = response.data.success.token;
             this.getUserName(user.username)
           }
         })
         .catch(error =>  {
           this.loading = false;
           this.message = error ? "Nom d'utilisateur ou mot de passe incorrect !" : '';
         });
    },
    getUserName(username) {
      this.$http.get('/api/users', {
        params: {
          sqlfilters: "(login:=:'" + username +"')"
        }
      })
          .then(async  (response) => {
            let tmp = response.data.pop(0);
            await this.$store.commit('setUser', { name: tmp.lastname, firstname: tmp.firstname, userId: tmp.id, entity: tmp.entity });
            await this.getListDocument();
            await this.getGlobalConfig();
            await this.$router.push('/');
            this.$notify({
              duration: 3000,
              title: 'Connection réussie',
              text: 'Vous êtes connecté en tant que ' + tmp.lastname,
              type: 'success'
            });
          })
    },
    async getGlobalConfig(){
     const partenaire = {
       address: "",
       country: null,
       email: "relationpartenaire.kam@koesio.com",
       firstname: "Service relation",
       id: "15",
       lastname: "partenaire",
       login: "relationpartenaire.kam.kam@koesio.com",
       office_phone: "04 27 24 00 39",
       town: "",
       user_mobile: "04 27 24 00 39",
       zip: "",
     }
      try {
        const res = await this.$http.get("/api/financement/global", {})
        let partners = res.data.partnergests;
        partners.push(partenaire)
        await this.$store.commit('setContractName', res.data.simulation_pdf.contrat)
        await this.$store.commit('setReportName', res.data.simulation_pdf.pv)
        await this.$store.commit('setContractTypes', res.data.contracttypes)
        await this.$store.commit('setPayementTypes', res.data.paymenttypes)
        await this.$store.commit('setLoyerMajores', res.data.loyermajores)
        await this.$store.commit('setTermes', res.data.termes)
        await this.$store.commit('setPeriodicites', res.data.periodicities[0])
        await this.$store.commit('setPrestations', res.data.prestations)
        await this.$store.commit('setPartner', partners)
        await this.$store.commit('setHomeMessages', res.data.messages.homemessage)
      }
      catch (error) {
        this.$notify({
          duration: 3000,
          text: 'Erreur lors de la récupération de la configuration de l\'utilisateur',
          type: 'error'
        });
      }
    },
    async getListDocument() {
      try {
        const res = await this.$http.get("/api/financement/dict/complianceDocType")
        await this.$store.commit('setLabelDocuments', res.data)
      }
      catch (error) {
        this.$notify({
          duration: 3000,
          text: error.response.data.error.message,
          type: 'error'
        });
      }
    },
  },
  mounted () {
    document.body.classList.add('bg-grey')
  },
  unmounted () {
    document.body.classList.remove('bg-grey')
  }
};
</script>

<style scoped>
  .login-image {
    max-width: 100%;
  }
  .flex{
    display: flex;
    flex-wrap: nowrap;
  }

  .btn-round span{
    color: white;
  }

  .kam-logo{
    color: white;
    width: 200px;
    font-size: 200px;
    position:relative;
    font-weight: 600;
    transform: rotate(-90deg) translate(-65%);
    -webkit-transform: rotate(-90deg) translate(-65%);
    -moz-transform: rotate(-90deg) translate(-65%);
    -ms-transform:rotate(-90deg) translate(-65%);
    -o-transform:rotate(-90deg) translate(-65%);
    line-height: 0;
  }

  .background-koesio{
    background-color: #6e398e;
    width: 100vw;
    position: absolute;
    left: calc(-50vw + 50%);
    height: 500px;
  }

  .login-form{
    background: rgb(242, 242, 242);
    padding-left: 50px;
    box-shadow: inset 20px 0px 0px indianred;
  }

  .mg2{
    margin-top: 200px;
  }

  .center{
    align-self: center;
  }

  .btn-round {
    display: block;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0 20px;
    height: 50px;
    background-color: #6e398e;
    border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    box-shadow: 0 10px 30px 0 #ab88bc;
    border: none;
    margin: 30px auto auto auto;
  }
  #formLogin label {
    color:#999 ;
  }

  .footer{
    color: gray;
  }
</style>


